import React, { Fragment } from 'react';

import './footer.css'

const Footer = () => {

    const media = [
        { font: 'fab fa-telegram', path: 'https://t.me/Stakingadmin', color: '#e46ea1' },
        { font: 'fab fa-twitter', path: 'https://twitter.com/WEALTH_GROW2023', color: '#2ca5e0' },
        { font: 'fab fa-instagram', path: 'https://instagram.com/wealthgrow.brokerage', color: '#25d366' },
        // { font: 'fas fa-phone-alt', path: '', color: 'gray' }
    ]

    return (
        <Fragment>
            <div className='bg_footer'>
              <div className='container'>
              <div className='footer'>
                    <div className='footer_logo'><img src="../img/logo.png" alt="" /></div>
                    <div className='footer_media'>
                        {media.map(each => (
                            <div className='each_media' onClick={()=>{window.open(each.path)}}><i className={`${each.font}`} ></i></div>
                        ))}
                    </div>
                </div>
              </div>
            </div>
        </Fragment>
    );
}

export default Footer;